<template>
  <a-table rowKey="id" :data-source="list" :pagination="pagination" :loading="listLoading" @change="handleTableChange">
    <a-table-column key="studentName" title="姓名" data-index="studentName" align="center" />

    <a-table-column key="userTimestamp" title="兑换时间" data-index="userTimestamp" align="center" />
    <a-table-column key="goodsName" title="商品名称" data-index="goodsName" align="center" />
    <a-table-column key="code" title="兑换码" data-index="code" align="center" />
  </a-table>
</template>

<script>
import * as redemptionCodeApi from '@/api/redemption-code'

export default {
  data() {
    return {
      query: {},
      pagination: {},
      listLoading: false,
      list: []
    }
  },
  methods: {
    initData(query = {}) {
      const { initPagination, getData } = this

      this.query = query
      this.pagination = initPagination()

      return getData()
    },
    getData() {
      const {
        pagination: { current: pageIndex, pageSize },
        query
      } = this

      this.listLoading = true

      return redemptionCodeApi
        .studentHistory({ ...query, pageIndex, pageSize })
        .then(res => {
          const { list, totalItems: total } = res.data

          this.list = list
          this.pagination.total = total
        })
        .finally(() => {
          this.listLoading = false
        })
    },
    initPagination() {
      return {
        current: 1,
        pageSize: 4,
        showTotal: total => {
          const h = this.$createElement
          const {
            pagination: { pageSize }
          } = this

          return h('div', {}, [
            h('span', '共 '),
            h('span', { class: 'primary-color' }, pageSize ? Math.ceil(total / pageSize) : Math.ceil(total / 4)),
            h('span', ' 页'),
            h('span', ' / '),
            h('span', { class: 'primary-color' }, total),
            h('span', ' 条数据')
          ])
        },
        total: 0
      }
    },
    handleTableChange(pagination, filters, sorter) {
      const { getData } = this

      this.pagination.current = pagination.current
      this.pagination.pageSize = pagination.pageSize

      getData()
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .ant-table-pagination.ant-pagination {
  margin: 24px;
}
</style>
