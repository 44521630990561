<template>
  <a-modal
    v-model="visible"
    title="使用兑换码"
    width="716px"
    :destroyOnClose="true"
    :bodyStyle="{ padding: 0 }"
    :ok-button-props="{ props: { shape: 'round', loading: buttonLoading } }"
    :cancel-button-props="{ props: { shape: 'round' } }"
    @ok="handleOk"
    @cancel="handlecancel"
  >
    <div v-show="!secondConfirm">
      <a-form-model ref="form" :model="form" v-bind="$formItemLayout" style="margin-top:16px">
        <a-form-model-item label="姓名">
          <span>{{ form.username }}</span>
        </a-form-model-item>
        <a-form-model-item label="账号">
          <span>{{ form.cellphone }}</span>
        </a-form-model-item>
        <a-form-model-item
          label="兑换码"
          prop="code"
          :rules="{
            required: true,
            message: '请填写兑换码',
            trigger: ['change', 'blur']
          }"
        >
          <a-input v-model.trim="form.code" placeholder="请输入内容" />
        </a-form-model-item>
      </a-form-model>
    </div>
    <!-- 二次确认信息 -->
    <div v-show="secondConfirm">
      <a-form-model :model="form" v-bind="$formItemLayout" style="margin-top:16px">
        <a-form-model-item label="姓名：">
          <span>{{ form.username }}</span>
        </a-form-model-item>
        <a-form-model-item label="账号：">
          <span>{{ form.cellphone }}</span>
        </a-form-model-item>
        <a-form-model-item label="兑换码：">
          <span>{{ form.code }}</span>
        </a-form-model-item>
        <a-form-model-item label="兑换内容:">
          <span>{{ serveContent.goodsName }}</span>
        </a-form-model-item>
      </a-form-model>
    </div>
  </a-modal>
</template>

<script>
import * as redemptionCodeApi from '@/api/redemption-code'

export default {
  data() {
    return {
      visible: false,
      buttonLoading: false,
      form: {
        studentId: undefined,
        code: ''
      },
      secondConfirm: false,
      serveContent: ''
    }
  },
  methods: {
    showModal({ studentId, username, cellphone }) {
      this.visible = true
      this.form = { studentId, username, cellphone, code: '' }
    },
    handleOk() {
      this.$refs.form.validate(valid => {
        if (valid) {
          // 获取兑换码的详细信息
          if (!this.secondConfirm) {
            redemptionCodeApi.codedetail({ redemptionCode: this.form.code }).then(res => {
              if (res.code === 0) {
                this.serveContent = res.data
                this.secondConfirm = true
              }
            })
          } else {
            // 二次确认信息后，调用接口
            const { studentId, code } = this.form

            this.buttonLoading = true
            redemptionCodeApi
              .useCode({ studentId, code })
              .then(res => {
                if (res.code === 0) {
                  this.visible = false
                  this.$notification.success({
                    message: '成功',
                    description: `兑换成功`
                  })
                } else if (res.code === 8014) {
                  this.visible = false
                  this.$notification.error({
                    message: '错误',
                    description: res.msg
                  })
                }
              })
              .finally(() => {
                this.secondConfirm = false
                this.buttonLoading = false
              })
          }
        }
      })
    },
    handlecancel() {
      this.secondConfirm = false
    }
  }
}
</script>

<style lang="less" scoped>
.ant-advanced-search-form /deep/ .ant-form-item {
  display: flex;
  margin-bottom: 0;
}
.ant-advanced-search-form /deep/ .ant-form-item-control-wrapper {
  flex: 1;
}
</style>
