<template>
  <a-form-model
    ref="form"
    :model="form"
    :labelCol="$formItemLayout.labelCol"
    :wrapperCol="{
      xs: {
        span: 24
      },
      sm: {
        span: 6
      }
    }"
  >
    <a-form-model-item
      label="机构"
      prop="mechanismId"
      :rules="{
        required: true,
        message: '请选择所属分类',
        trigger: 'blur'
      }"
    >
      <h-cascader
        :options="mechanismOptions"
        :value="defaultMechanism"
        :showSearch="true"
        change-on-select
        @change="onChange"
        :fieldNames="{ label: 'name', value: 'id', children: 'childrenList' }"
        placeholder="请选择"
      >
      </h-cascader>
    </a-form-model-item>
    <a-form-model-item
      label="所属分类"
      prop="type"
      :rules="{
        required: true,
        message: '请选择所属分类',
        trigger: 'blur'
      }"
    >
      <a-radio-group v-model="form.type" @change="handleChangeType()">
        <a-radio v-for="typeInfo in typeOptions" :key="typeInfo.value" :value="typeInfo.value">
          {{ typeInfo.label }}
        </a-radio>
      </a-radio-group>
    </a-form-model-item>
    <a-form-model-item label="会员天数:" v-if="form.type === 'MEMBER'" key="MEMBER">
      <a-row type="flex" :gutter="8">
        <a-col :span="12">
          <a-form-model-item
            prop="memberDate"
            :rules="{
              required: true,
              message: '请输入或选择天数',
              trigger: 'blur'
            }"
          >
            <a-input-number placeholder="请输入或选择天数" v-model="form.memberDate" :min="0" style="width:100%" />
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item
            prop="memberType"
            :rules="{
              required: true,
              message: '请选择类型',
              trigger: 'blur'
            }"
          >
            <a-select v-model="form.memberType" placeholder="请选择类型">
              <a-select-option value="DAY">天</a-select-option>
              <a-select-option value="MONTH">月</a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model-item>
    <a-form-model-item
      key="SKILL"
      v-if="form.type === 'SKILL'"
      label="技能"
      prop="goodsId"
      :rules="{
        required: true,
        message: '请选择技能',
        trigger: 'blur'
      }"
    >
      <a-button class="text-bg-btn" @click="$refs.skillModal.showModal()">
        {{ form.goodsId ? skillInfo.goodsName : '选择技能' }}<a-icon type="edit" />
      </a-button>
    </a-form-model-item>

    <a-form-model-item
      key="SERVICE"
      v-if="form.type === 'SERVICE'"
      label="服务"
      prop="goodsId"
      :rules="{
        required: true,
        message: '请选择服务',
        trigger: 'blur'
      }"
    >
      <a-button class="text-bg-btn" @click="$refs.serviceModal.showModal()">
        {{ form.goodsId ? serviceInfo.goodsName : '选择服务' }}<a-icon type="edit" />
      </a-button>
    </a-form-model-item>

    <a-form-model-item
      label="有效期限"
      prop="validityTerm"
      :rules="{
        required: true,
        message: '请填写有效期限',
        trigger: 'blur'
      }"
    >
      <a-row type="flex" :gutter="8">
        <a-col flex="1">
          <a-input-number v-model="form.validityTerm" :min="1" style="width:100%" :precision="0" />
        </a-col>
        <a-col>
          <span>天</span>
        </a-col>
      </a-row>
    </a-form-model-item>
    <a-form-model-item
      label="数量"
      prop="codeNumber"
      :rules="{
        required: true,
        message: '请填写数量',
        trigger: 'blur'
      }"
    >
      <a-row type="flex" :gutter="8">
        <a-col flex="1">
          <a-input-number v-model="form.codeNumber" :min="1" style="width:100%" :precision="0" />
        </a-col>
        <a-col>
          <span>个</span>
        </a-col>
      </a-row>
    </a-form-model-item>
    <service-modal ref="serviceModal" @submit="handleServiceChange"></service-modal>
    <skill-modal ref="skillModal" @submit="handleSkillChange"></skill-modal>
  </a-form-model>
</template>
<script>
import { cloneDeep } from 'lodash'
import * as commonApi from '@/api/common'
import { REDEMTION_CODE_TYPE } from '@/store/dictionary'
import SkillModal from './SkillModal'
import ServiceModal from './ServiceModal'

export default {
  components: {
    SkillModal,
    ServiceModal
  },
  data() {
    return {
      form: {
        codeNumber: undefined,
        goodsId: undefined,
        type: 'SKILL',
        memberDate: undefined,
        memberType: undefined,
        validityTerm: undefined,
        mechanismId: ''
      },
      skillInfo: {},
      serviceInfo: {},
      checkingName: false,
      typeOptions: REDEMTION_CODE_TYPE,
      mechanismOptions: [],
      defaultMechanism: [],
      resourceListErr: ''
    }
  },
  mounted() {
    commonApi.mechanismOptions().then(res => {
      this.mechanismOptions = res.data
    })
  },
  methods: {
    handleChangeType() {
      this.form.goodsId = undefined
      this.form.memberDate = undefined
      this.form.memberType = undefined
    },
    initData(form) {
      this.form = form
    },
    formatForm() {
      const ret = cloneDeep(this.form)

      return { ...ret }
    },
    handleSkillChange(skill) {
      if (skill) {
        this.form.goodsId = skill.goodsId
        this.skillInfo = skill
        this.$refs.form.clearValidate('goodsId')
      }
    },
    handleServiceChange(service) {
      if (service) {
        this.form.goodsId = service.goodsId
        this.serviceInfo = service
        this.$refs.form.clearValidate('goodsId')
      }
    },
    onChange(record) {
      this.defaultMechanism = record
      this.$set(this.form, 'mechanismId', record[record.length - 1])
    }
  }
}
</script>

<style lang="less" scoped></style>
