<template>
  <a-table rowKey="id" :data-source="list" :pagination="pagination" :loading="listLoading" @change="handleTableChange">
    <a-table-column
      key="createTimestamp"
      title="生成日期"
      data-index="createTimestamp"
      align="center"
      :ellipsis="true"
      width="15%"
    >
      <template slot-scope="createTimestamp">
        <span>
          {{ createTimestamp | moment('YYYY-MM-DD') }}
        </span>
      </template>
    </a-table-column>

    <a-table-column
      key="userTimestamp"
      title="使用日期"
      data-index="userTimestamp"
      align="center"
      :ellipsis="true"
      width="15%"
    >
      <template slot-scope="createTimestamp">
        <span>
          {{ createTimestamp | moment('YYYY-MM-DD') }}
        </span>
      </template>
    </a-table-column>

    <a-table-column key="code" title="兑换码" data-index="code" align="center" :ellipsis="true" width="40%" />

    <a-table-column
      key="cellPhone"
      title="兑换账号"
      data-index="cellPhone"
      align="center"
      :ellipsis="true"
      width="20%"
    />

    <a-table-column key="status" title="状态" data-index="status" align="center" :ellipsis="true" width="10%">
      <template slot-scope="status">
        <span>
          {{ status | RedemptionStatusText() }}
        </span>
      </template>
    </a-table-column>
  </a-table>
</template>

<script>
import * as redemptionCodeApi from '@/api/redemption-code'

export default {
  data() {
    return {
      query: {},
      pagination: {},
      listLoading: false,
      list: []
    }
  },
  activated() {
    const { ready, getData } = this

    if (ready) {
      getData()
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.ready = true
    })
  },
  methods: {
    initData(query = {}) {
      const { initPagination, getData } = this

      this.query = query
      this.pagination = initPagination()

      return getData()
    },
    getData() {
      const {
        pagination: { current: pageIndex, pageSize },
        query
      } = this

      this.listLoading = true

      return redemptionCodeApi
        .detail({ ...query, pageIndex, pageSize })
        .then(res => {
          const { list, totalItems: total } = res.data

          this.list = list
          this.pagination.total = total
        })
        .finally(() => {
          this.listLoading = false
        })
    },
    initPagination() {
      return {
        current: 1,
        pageSize: 10,
        showTotal: total => {
          const h = this.$createElement
          const {
            pagination: { pageSize }
          } = this

          return h('div', {}, [
            h('span', '共 '),
            h('span', { class: 'primary-color' }, pageSize ? Math.ceil(total / pageSize) : Math.ceil(total / 10)),
            h('span', ' 页'),
            h('span', ' / '),
            h('span', { class: 'primary-color' }, total),
            h('span', ' 条数据')
          ])
        },
        showSizeChanger: true,
        total: 0,
        pageSizeOptions: ['10', '20', '50', '100']
      }
    },
    handleTableChange(pagination, filters, sorter) {
      const { getData } = this

      this.pagination.current = pagination.current
      this.pagination.pageSize = pagination.pageSize

      getData()
    }
  }
}
</script>

<style lang="less" scoped>
.desc {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ant-divider,
.ant-divider-vertical {
  margin: 0 16px;
}
/deep/ .ant-table-pagination.ant-pagination {
  margin: 24px;
}
@media (max-width: 1919px) {
  /deep/ .ant-btn {
    font-size: 12px;
  }
}
</style>
