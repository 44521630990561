<template>
  <div>
    <a-table
      rowKey="id"
      :data-source="list"
      :pagination="pagination"
      :loading="listLoading"
      :row-selection="{ onSelect: onSelectChange, onSelectAll: onSelectAll }"
      @change="handleTableChange"
    >
      <a-table-column key="username" title="姓名" data-index="username" align="center" :ellipsis="true" width="10%" />

      <a-table-column key="sex" title="性别" data-index="sex" align="center" :ellipsis="true" width="10%">
        <template slot-scope="sex">
          <span>
            {{ { MALE: '男', FEMALE: '女' }[sex] }}
          </span>
        </template>
      </a-table-column>

      <a-table-column key="age" title="年龄" data-index="age" align="center" :ellipsis="true" width="10%" />

      <a-table-column
        key="createTimestamp"
        title="注册时间"
        data-index="createTimestamp"
        align="center"
        :ellipsis="true"
        width="20%"
      />
      <a-table-column key="cellphone" title="账号" data-index="cellphone" align="center" :ellipsis="true" width="35%" />

      <a-table-column key="actions" title="操作" align="center" :ellipsis="true" width="15%">
        <template slot-scope="record">
          <div style="display: flex; justify-content: center">
            <div style="display: flex; flex-direction: column; align-items: flex-start">
              <a-button
                v-if="$auth('redemptionCode_student_use')"
                type="link"
                size="small"
                @click="handleDetail(record)"
                ><a-icon type="eye" />查看</a-button
              >
              <a-button v-if="$auth('redemptionCode_use')" type="link" size="small" @click="handleUse(record)">
                <a-icon type="edit" />使用兑换码
              </a-button>
            </div>
          </div>
        </template>
      </a-table-column>
    </a-table>

    <history-modal ref="history"></history-modal>
    <usage-modal ref="usage"></usage-modal>
  </div>
</template>

<script>
import * as studentApi from '@/api/student'
import HistoryModal from './HistoryModal'
import UsageModal from './UsageModal'

export default {
  components: {
    HistoryModal,
    UsageModal,
  },
  data() {
    return {
      query: {},
      pagination: {},
      listLoading: false,
      list: [], // 所有用户
      selectUserList: [], // 选中的用户
    }
  },
  activated() {
    const { ready, getData } = this

    if (ready) {
      getData()
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.ready = true
    })
  },
  methods: {
    onSelectChange(record, selected, selectedRows) {
      // selected表示当前是否选中, record是当前行的所有数据
      if (selected) {
        this.selectUserList = this.selectUserList.concat(record)
      } else {
        this.selectUserList.forEach((item, index) => {
          if (item.id === record.id) {
            this.selectUserList.splice(index, 1)
          }
        })
      }
      this.$emit('hanlderMulChoice', this.selectUserList)
    },
    onSelectAll(selected, selectedRows, changeRows) {
      // selected表示当前是否选中, changeRows是本次选中的所有行的数据
      if (selected) {
        this.selectUserList = this.selectUserList.concat(changeRows)
      } else {
        const deletIdArr = changeRows.map((item) => {
          return item.id
        })
        deletIdArr.forEach((el) => {
          this.selectUserList.forEach((item, index) => {
            if (item.id === el) {
              this.selectUserList.splice(index, 1)
            }
          })
        })
      }
      this.$emit('hanlderMulChoice', this.selectUserList)
    },
    initData(query = {}) {
      const { initPagination, getData } = this

      this.query = query
      this.pagination = initPagination()

      return getData()
    },
    getData() {
      const {
        pagination: { current: pageIndex, pageSize },
        query,
      } = this

      this.listLoading = true

      return studentApi
        .list({ ...query, pageIndex, pageSize })
        .then((res) => {
          const { list, totalItems: total } = res.data

          this.list = list
          this.pagination.total = total
        })
        .finally(() => {
          this.listLoading = false
        })
    },
    initPagination() {
      return {
        current: 1,
        pageSize: 10,
        showTotal: (total) => {
          const h = this.$createElement
          const {
            pagination: { pageSize },
          } = this

          return h('div', {}, [
            h('span', '共 '),
            h('span', { class: 'primary-color' }, pageSize ? Math.ceil(total / pageSize) : Math.ceil(total / 10)),
            h('span', ' 页'),
            h('span', ' / '),
            h('span', { class: 'primary-color' }, total),
            h('span', ' 条数据'),
          ])
        },
        showSizeChanger: true,
        total: 0,
        pageSizeOptions: ['10', '20', '50', '100'],
      }
    },
    handleTableChange(pagination, filters, sorter) {
      const { getData } = this

      this.pagination.current = pagination.current
      this.pagination.pageSize = pagination.pageSize

      getData()
    },
    handleDetail(record) {
      const { id: studentId } = record

      this.$refs.history.showModal({ studentId })
    },
    handleUse(record) {
      const { id: studentId, username, cellphone } = record

      this.$refs.usage.showModal({ studentId, username, cellphone })
    },
  },
}
</script>

<style lang="less" scoped>
.desc {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ant-divider,
.ant-divider-vertical {
  margin: 0 16px;
}
/deep/ .ant-table-pagination.ant-pagination {
  margin: 24px;
}
@media (max-width: 1919px) {
  /deep/ .ant-btn {
    font-size: 12px;
  }
}
</style>
