<template>
  <div>
    <a-card style="margin-bottom:24px">
      <a-row type="flex" :gutter="[16, 16]">
        <a-col :span="8" class="option-wrapper">
          <span>兑换码：</span>
          <a-input-search
            v-model="query.code"
            allowClear
            placeholder="请输入兑换码"
            style="flex:1"
            @search="initData()"
          />
        </a-col>
        <a-col :span="6" class="option-wrapper">
          <span>状态：</span>
          <h-select v-model="query.status" allowClear placeholder="请选择" style="flex:1" @change="initData()">
            <a-select-option v-for="statusInfo in statusOptions" :key="statusInfo.value" :value="statusInfo.value">
              {{ statusInfo.label }}
            </a-select-option>
          </h-select>
        </a-col>
      </a-row>
    </a-card>

    <a-card :bodyStyle="{ padding: 0 }">
      <div slot="extra">
        <a-button shape="round" @click="$emit('back')"><a-icon type="left" />返回</a-button>
      </div>
      <main-table ref="table"></main-table>
    </a-card>
  </div>
</template>

<script>
import { REDEMTION_CODE_STATUS } from '@/store/dictionary'
import MainTable from './components/Table'

export default {
  components: {
    MainTable
  },
  data() {
    return {
      query: {
        code: '',
        status: undefined
      },
      statusOptions: REDEMTION_CODE_STATUS
    }
  },
  methods: {
    initData(query = {}) {
      this.query = { ...this.query, ...query }

      this.$refs.table.initData(this.query)
    }
  }
}
</script>

<style lang="less" scoped>
.option-wrapper {
  display: flex;
  align-items: center;
}
</style>
