<template>
  <a-modal
    v-model="visible"
    title="查看兑换详情"
    width="716px"
    :destroyOnClose="true"
    :bodyStyle="{ padding: 0 }"
    :footer="false"
  >
    <main-table ref="table"></main-table>
  </a-modal>
</template>

<script>
import MainTable from './HistoryTable'

export default {
  components: {
    MainTable
  },
  data() {
    return {
      visible: false
    }
  },
  methods: {
    showModal(query = {}) {
      this.visible = true

      this.query = query

      this.initData()
    },
    initData() {
      const { $nextTick, query, $refs } = this

      $nextTick(() => {
        $refs.table.initData({ ...query })
      })
    }
  }
}
</script>

<style lang="less" scoped>
.ant-advanced-search-form /deep/ .ant-form-item {
  display: flex;
  margin-bottom: 0;
}
.ant-advanced-search-form /deep/ .ant-form-item-control-wrapper {
  flex: 1;
}
</style>
