<template>
  <a-table rowKey="id" :data-source="list" :pagination="pagination" :loading="listLoading" @change="handleTableChange">
    <a-table-column
      key="createTimestamp"
      title="创建时间"
      data-index="createTimestamp"
      align="center"
      :ellipsis="true"
      width="12%"
    >
      <template slot-scope="createTimestamp">
        <span>
          {{ createTimestamp | moment('YYYY-MM-DD') }}
        </span>
      </template>
    </a-table-column>

    <a-table-column
      key="codeNumber"
      title="兑换码数量（个）"
      data-index="codeNumber"
      align="center"
      :ellipsis="true"
      width="13%"
    />

    <a-table-column
      key="validityTerm"
      title="有效期（天）"
      data-index="validityTerm"
      align="center"
      :ellipsis="true"
      width="12%"
    />

    <a-table-column
      key="mechanismName"
      title="机构名称"
      data-index="mechanismName"
      align="center"
      :ellipsis="true"
      width="17%"
    />

    <a-table-column key="type" title="所属分类" data-index="type" align="center" :ellipsis="true" width="10%">
      <template slot-scope="type">
        <span>
          {{ type | RedemptionCodeTypeText() }}
        </span>
      </template>
    </a-table-column>

    <a-table-column
      key="goodsName"
      title="商品名称"
      data-index="goodsName"
      align="center"
      :ellipsis="true"
      width="17%"
    />

    <a-table-column key="actions" title="操作" align="center" :ellipsis="true" width="19%">
      <template slot-scope="record">
        <a-space>
          <a-button v-if="$auth('redemptionCode_detail')" type="link" size="small" @click="handleDetail(record)">
            <a-icon type="right-circle" />详情
          </a-button>
          <a-button v-if="$auth('redemptionCode_export')" type="link" size="small" @click="handleExcelExport(record)">
            <a-icon type="profile" />导出Excel
          </a-button>
        </a-space>
      </template>
    </a-table-column>
  </a-table>
</template>

<script>
import axios from 'axios'
import storage from 'store'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import * as redemptionCodeApi from '@/api/redemption-code'

export default {
  data() {
    return {
      query: {},
      pagination: {},
      listLoading: false,
      list: [],
    }
  },
  activated() {
    const { ready, getData } = this

    if (ready) {
      getData()
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.ready = true
    })
  },
  methods: {
    initData(query = {}) {
      const { initPagination, getData } = this

      this.query = query
      this.pagination = initPagination()

      return getData()
    },
    getData() {
      const {
        pagination: { current: pageIndex, pageSize },
        query,
      } = this

      this.listLoading = true

      return redemptionCodeApi
        .list({ ...query, pageIndex, pageSize })
        .then((res) => {
          const { list, totalItems: total } = res.data

          this.list = list
          this.pagination.total = total
        })
        .finally(() => {
          this.listLoading = false
        })
    },
    initPagination() {
      return {
        current: 1,
        pageSize: 10,
        showTotal: (total) => {
          const h = this.$createElement
          const {
            pagination: { pageSize },
          } = this

          return h('div', {}, [
            h('span', '共 '),
            h('span', { class: 'primary-color' }, pageSize ? Math.ceil(total / pageSize) : Math.ceil(total / 10)),
            h('span', ' 页'),
            h('span', ' / '),
            h('span', { class: 'primary-color' }, total),
            h('span', ' 条数据'),
          ])
        },
        showSizeChanger: true,
        total: 0,
        pageSizeOptions: ['10', '20', '50', '100'],
      }
    },
    handleTableChange(pagination, filters, sorter) {
      const { getData } = this

      this.pagination.current = pagination.current
      this.pagination.pageSize = pagination.pageSize

      getData()
    },
    handleDetail(record) {
      this.$emit('detail-check', record)
    },
    handleExcelExport(record) {
      const token = storage.get(ACCESS_TOKEN)
      axios({
        url: process.env.VUE_APP_API_BASE_URL + '/redemptionCode/export/' + record.id,
        method: 'get',
        responseType: 'arraybuffer',
        headers: {
          Call_Source: 'health_admin',
          Authorization: token,
        },
      }).then((res) => {
        const blob = new Blob([res.data], { type: 'application/vnd.ms-excel' })
        const href = window.URL.createObjectURL(blob)
        window.location.href = href
      })
    },
  },
}
</script>

<style lang="less" scoped>
.desc {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ant-divider,
.ant-divider-vertical {
  margin: 0 16px;
}
/deep/ .ant-table-pagination.ant-pagination {
  margin: 24px;
}
@media (max-width: 1919px) {
  /deep/ .ant-btn {
    font-size: 12px;
  }
}
</style>
