<template>
  <a-modal
    v-model="visible"
    title="批量兑换"
    width="900px"
    :destroyOnClose="true"
    :bodyStyle="{ padding: 0 }"
    :ok-button-props="{ props: { shape: 'round', loading: buttonLoading, disabled: buttondisabled } }"
    :cancel-button-props="{ props: { shape: 'round' } }"
    @ok="handleOk"
  >
    <a-row class="searchForm">
      <a-col :span="12" class="option-wrapper">
        <span>创建时间：</span>
        <a-date-picker v-model="query.createTimestamp" valueFormat="YYYY-MM-DD" style="flex:1" @change="initData" />
      </a-col>
      <a-col :span="12" class="option-wrapper">
        <span>商品名称：</span>
        <a-input-search
          v-model="query.goodsName"
          allowClear
          placeholder="请输入商品名称"
          style="flex:1"
          @search="initData"
        />
      </a-col>
    </a-row>
    <a-table
      rowKey="id"
      :data-source="list"
      :pagination="pagination"
      :loading="listLoading"
      :row-selection="{ type: 'radio', onSelect: onSelect }"
      @change="handleTableChange"
    >
      <a-table-column key="createTimestamp" title="创建时间" data-index="createTimestamp" align="center" width="15%">
        <template slot-scope="createTimestamp">
          <span>
            {{ createTimestamp | moment('YYYY-MM-DD') }}
          </span>
        </template>
      </a-table-column>
      <a-table-column
        key="notUseCodeNumber"
        title="可用兑换码数量（个）"
        data-index="notUseCodeNumber"
        align="center"
        :ellipsis="true"
        width="22%"
      />

      <a-table-column
        key="validityTerm"
        title="有效期（天）"
        data-index="validityTerm"
        align="center"
        :ellipsis="true"
        width="16%"
      />

      <a-table-column
        key="mechanismName"
        title="机构名称"
        data-index="mechanismName"
        align="center"
        :ellipsis="true"
        width="25%"
      />
      <a-table-column
        key="goodsName"
        title="商品名称"
        data-index="goodsName"
        align="center"
        :ellipsis="true"
        width="20%"
      />
    </a-table>
  </a-modal>
</template>

<script>
import * as redemptionCodeApi from '@/api/redemption-code'

export default {
  data() {
    return {
      query: {
        createTimestamp: null,
        goodsName: undefined,
        list: [] // 选中的学员
      },
      pagination: {},
      buttonLoading: false,
      buttondisabled: false, // 确定按钮是否可操作
      listLoading: false,
      list: [], // 列表
      visible: false,
      selectRow: {} // 选中每一条数据
    }
  },
  methods: {
    handleOk() {
      if (this.selectRow.id) {
        this.visible = false
        this.$emit('handlesecondconfirm', { selectCodeObj: this.selectRow })
      } else {
        this.$notification.error({
          message: '错误',
          description: '请选择兑换码'
        })
      }
    },
    showModal(query = {}) {
      this.visible = true

      this.query = query

      this.initData()
    },
    // 选中的行
    onSelect(records) {
      if (records.notUseCodeNumber < this.query.list.length) {
        this.$notification.error({
          message: '错误',
          description: '可用兑换码数量少于用户数'
        })
        this.buttondisabled = true
      } else {
        this.buttondisabled = false
        this.selectRow = records
      }
    },
    initData() {
      const { initPagination, getData } = this

      this.pagination = initPagination()

      return getData()
    },
    getData() {
      const { createTimestamp, goodsName } = this.query
      const {
        pagination: { current: pageIndex, pageSize }
      } = this

      this.listLoading = true

      return redemptionCodeApi
        .list({ createTimestamp, goodsName, pageIndex, pageSize, canSelectNoUseCode: 'true', status: 'UNEXPIRED' })
        .then(res => {
          const { list, totalItems: total } = res.data
          this.list = list
          this.pagination.total = total
        })
        .finally(() => {
          this.listLoading = false
        })
    },
    initPagination() {
      return {
        current: 1,
        pageSize: 5,
        showTotal: total => {
          const h = this.$createElement
          const {
            pagination: { pageSize }
          } = this

          return h('div', {}, [
            h('span', '共 '),
            h('span', { class: 'primary-color' }, pageSize ? Math.ceil(total / pageSize) : Math.ceil(total / 4)),
            h('span', ' 页'),
            h('span', ' / '),
            h('span', { class: 'primary-color' }, total),
            h('span', ' 条数据')
          ])
        },
        total: 0
      }
    },
    handleTableChange(pagination, filters, sorter) {
      const { getData } = this

      this.pagination.current = pagination.current
      this.pagination.pageSize = pagination.pageSize

      getData()
    }
  }
}
</script>

<style lang="less" scoped>
.searchForm {
  margin: 10px 10px 50px;
  .option-wrapper {
    > span {
      margin: 0 0 0 10px;
    }
  }
}
/deep/ .ant-table-pagination.ant-pagination {
  margin: 24px;
}
</style>
