<template>
  <div>
    <a-page-header>
      <template slot="footer">
        <a-card :body-style="{ padding: '0 24px' }">
          <a-tabs v-model="current">
            <a-tab-pane key="0" tab="兑换码生成" v-if="permissionsArray[0]" />
            <a-tab-pane key="1" tab="兑换码查看" v-if="permissionsArray[1]" />
            <a-tab-pane key="2" tab="兑换码使用" v-if="permissionsArray[2]" />
          </a-tabs>
        </a-card>
      </template>
    </a-page-header>
    <div style="margin-top: 48px">
      <generate-main v-if="current == 0" @success="current = 1"></generate-main>
      <list-main v-if="current == 1"></list-main>
      <usage-main v-if="current == 2"></usage-main>
    </div>
  </div>
</template>

<script>
import GenerateMain from './generate/index'
import ListMain from './list/index'
import UsageMain from './usage/index'
export default {
  name: 'RedemptionCodeManagementIndex',
  components: {
    GenerateMain,
    ListMain,
    UsageMain,
  },
  created() {
    const tempPermissionsArray = [
      this.$auth(['redemptionCode_create']),
      this.$auth(['redemptionCode_select']),
      this.$auth(['use_redemptionCode']),
    ]
    // 需要显示标签转为索引
    this.permissionsArray = tempPermissionsArray.map((item, index) => {
      return !item ? false : index + ''
    })
    // 过滤掉布尔值,取需要显示的第一个索引
    this.current = this.permissionsArray.filter(Boolean)[0]
  },
  data() {
    return {
      current: null,
      permissionsArray: [],
    }
  },
}
</script>

<style lang="less" scoped>
.ant-page-header {
  margin: -24px;
  /deep/ .ant-page-header-footer .ant-tabs-bar .ant-tabs-nav .ant-tabs-tab {
    margin-right: 16px;
    padding: 16px 0;
  }
  /deep/ .ant-tabs-ink-bar {
    height: 3px;
  }
}
</style>
