<template>
  <a-modal
    v-model="visible"
    title="确认兑换"
    width="900px"
    :destroyOnClose="true"
    :bodyStyle="{ padding: 0 }"
    :ok-button-props="{ props: { shape: 'round', loading: buttonLoading } }"
    :cancel-button-props="{ props: { shape: 'round' } }"
    @ok="handleOk"
  >
    <a-card>
      <div slot="title">兑换内容</div>
      <a-row class="searchForm">
        <a-col :span="12" class="option-wrapper">
          <span style="font-weight: bold">类型：</span>
          <span>{{ redemptionCodeType }}</span>
        </a-col>
        <a-col :span="12" class="option-wrapper">
          <span style="font-weight: bold">商品名称：</span>
          <span>{{ selectCodeObj.goodsName }}</span>
        </a-col>
      </a-row>
    </a-card>
    <a-card>
      <div slot="title">兑换用户</div>
      <a-table :data-source="selectUserList" :pagination="pagination">
        <a-table-column key="username" title="姓名" data-index="username" align="center" :ellipsis="true" width="10%" />
        <a-table-column key="sex" title="性别" data-index="sex" align="center" :ellipsis="true" width="10%">
          <template slot-scope="sex">
            <span>
              {{ { MALE: '男', FEMALE: '女' }[sex] }}
            </span>
          </template>
        </a-table-column>
        <a-table-column key="age" title="年龄" data-index="age" align="center" :ellipsis="true" width="10%" />
        <a-table-column
          key="createTimestamp"
          title="注册时间"
          data-index="createTimestamp"
          align="center"
          :ellipsis="true"
          width="20%"
        />
        <a-table-column
          key="cellphone"
          title="账号"
          data-index="cellphone"
          align="center"
          :ellipsis="true"
          width="20%"
        />
      </a-table>
    </a-card>
  </a-modal>
</template>

<script>
import * as redemptionCodeApi from '@/api/redemption-code'
export default {
  data() {
    return {
      visible: false,
      query: {
        redemptionCodeId: {},
        studentIds: []
      },
      buttonLoading: false,
      pagination: {
        pageSize: 5
      },
      selectCodeObj: {}, // 选中的兑换码
      selectUserList: [] // 选中的用户
    }
  },
  computed: {
    redemptionCodeType() {
      let type = ''
      switch (this.selectCodeObj.type) {
        case 'SKILL':
          type = '技能'
          break
        case 'SERVICE':
          type = '服务'
          break
        case 'MEMBER':
          type = '会员'
          break
        default:
          return
      }
      return type
    }
  },
  methods: {
    handleOk() {
      this.buttonLoading = true
      redemptionCodeApi
        .batchUser({ redemptionCodeId: this.query.redemptionCodeId, studentIds: this.query.studentIds.join(',') })
        .then(res => {
          const { code } = res
          code === 0 && this.$message.success(`批量兑换成功`)
          this.visible = false
        })
        .finally(() => (this.buttonLoading = false))
    },
    showModal(query = {}) {
      this.visible = true
      this.selectUserList = query.selectUserList
      this.selectCodeObj = query.selectCodeObj
      // 获取兑换码id和选中的用户id
      this.query.redemptionCodeId = query.selectCodeObj.id
      query.selectUserList.forEach(item => {
        this.query.studentIds.push(item.id)
      })
    }
  }
}
</script>

<style lang="less" scoped>
.searchForm {
  margin: 10px;
  .option-wrapper {
    > span {
      margin: 0 0 0 10px;
    }
  }
}
/deep/ .ant-table-pagination.ant-pagination {
  margin: 24px;
}
</style>
