var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-form-model',{ref:"form",attrs:{"model":_vm.form,"labelCol":_vm.$formItemLayout.labelCol,"wrapperCol":{
    xs: {
      span: 24
    },
    sm: {
      span: 6
    }
  }}},[_c('a-form-model-item',{attrs:{"label":"机构","prop":"mechanismId","rules":{
      required: true,
      message: '请选择所属分类',
      trigger: 'blur'
    }}},[_c('h-cascader',{attrs:{"options":_vm.mechanismOptions,"value":_vm.defaultMechanism,"showSearch":true,"change-on-select":"","fieldNames":{ label: 'name', value: 'id', children: 'childrenList' },"placeholder":"请选择"},on:{"change":_vm.onChange}})],1),_c('a-form-model-item',{attrs:{"label":"所属分类","prop":"type","rules":{
      required: true,
      message: '请选择所属分类',
      trigger: 'blur'
    }}},[_c('a-radio-group',{on:{"change":function($event){return _vm.handleChangeType()}},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}},_vm._l((_vm.typeOptions),function(typeInfo){return _c('a-radio',{key:typeInfo.value,attrs:{"value":typeInfo.value}},[_vm._v(" "+_vm._s(typeInfo.label)+" ")])}),1)],1),(_vm.form.type === 'MEMBER')?_c('a-form-model-item',{key:"MEMBER",attrs:{"label":"会员天数:"}},[_c('a-row',{attrs:{"type":"flex","gutter":8}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-model-item',{attrs:{"prop":"memberDate","rules":{
            required: true,
            message: '请输入或选择天数',
            trigger: 'blur'
          }}},[_c('a-input-number',{staticStyle:{"width":"100%"},attrs:{"placeholder":"请输入或选择天数","min":0},model:{value:(_vm.form.memberDate),callback:function ($$v) {_vm.$set(_vm.form, "memberDate", $$v)},expression:"form.memberDate"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-model-item',{attrs:{"prop":"memberType","rules":{
            required: true,
            message: '请选择类型',
            trigger: 'blur'
          }}},[_c('a-select',{attrs:{"placeholder":"请选择类型"},model:{value:(_vm.form.memberType),callback:function ($$v) {_vm.$set(_vm.form, "memberType", $$v)},expression:"form.memberType"}},[_c('a-select-option',{attrs:{"value":"DAY"}},[_vm._v("天")]),_c('a-select-option',{attrs:{"value":"MONTH"}},[_vm._v("月")])],1)],1)],1)],1)],1):_vm._e(),(_vm.form.type === 'SKILL')?_c('a-form-model-item',{key:"SKILL",attrs:{"label":"技能","prop":"goodsId","rules":{
      required: true,
      message: '请选择技能',
      trigger: 'blur'
    }}},[_c('a-button',{staticClass:"text-bg-btn",on:{"click":function($event){return _vm.$refs.skillModal.showModal()}}},[_vm._v(" "+_vm._s(_vm.form.goodsId ? _vm.skillInfo.goodsName : '选择技能')),_c('a-icon',{attrs:{"type":"edit"}})],1)],1):_vm._e(),(_vm.form.type === 'SERVICE')?_c('a-form-model-item',{key:"SERVICE",attrs:{"label":"服务","prop":"goodsId","rules":{
      required: true,
      message: '请选择服务',
      trigger: 'blur'
    }}},[_c('a-button',{staticClass:"text-bg-btn",on:{"click":function($event){return _vm.$refs.serviceModal.showModal()}}},[_vm._v(" "+_vm._s(_vm.form.goodsId ? _vm.serviceInfo.goodsName : '选择服务')),_c('a-icon',{attrs:{"type":"edit"}})],1)],1):_vm._e(),_c('a-form-model-item',{attrs:{"label":"有效期限","prop":"validityTerm","rules":{
      required: true,
      message: '请填写有效期限',
      trigger: 'blur'
    }}},[_c('a-row',{attrs:{"type":"flex","gutter":8}},[_c('a-col',{attrs:{"flex":"1"}},[_c('a-input-number',{staticStyle:{"width":"100%"},attrs:{"min":1,"precision":0},model:{value:(_vm.form.validityTerm),callback:function ($$v) {_vm.$set(_vm.form, "validityTerm", $$v)},expression:"form.validityTerm"}})],1),_c('a-col',[_c('span',[_vm._v("天")])])],1)],1),_c('a-form-model-item',{attrs:{"label":"数量","prop":"codeNumber","rules":{
      required: true,
      message: '请填写数量',
      trigger: 'blur'
    }}},[_c('a-row',{attrs:{"type":"flex","gutter":8}},[_c('a-col',{attrs:{"flex":"1"}},[_c('a-input-number',{staticStyle:{"width":"100%"},attrs:{"min":1,"precision":0},model:{value:(_vm.form.codeNumber),callback:function ($$v) {_vm.$set(_vm.form, "codeNumber", $$v)},expression:"form.codeNumber"}})],1),_c('a-col',[_c('span',[_vm._v("个")])])],1)],1),_c('service-modal',{ref:"serviceModal",on:{"submit":_vm.handleServiceChange}}),_c('skill-modal',{ref:"skillModal",on:{"submit":_vm.handleSkillChange}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }