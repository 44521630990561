<template>
  <a-card>
    <basis-form ref="basis" />

    <a-row>
      <a-col v-bind="$formItemLayoutWithoutLabel.wrapperCol">
        <a-button
          :disabled="!$auth('redemptionCode_create')"
          :loading="loading"
          type="primary"
          shape="round"
          style="width:160px"
          @click="onSubmit"
        >
          <span>生成兑换码</span>
        </a-button>
      </a-col>
    </a-row>
  </a-card>
</template>

<script>
import * as redemptionCodeApi from '@/api/redemption-code'
import BasisForm from './components/BasisForm'

export default {
  components: {
    BasisForm
  },
  data() {
    return {
      loading: false
    }
  },
  methods: {
    onSubmit() {
      const { $refs, $notification } = this

      $refs.basis.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          try {
            const { formatForm } = $refs.basis
            const form = formatForm()
            redemptionCodeApi
              .create(form)
              .then(res => {
                $notification['success']({ message: '操作成功' })
                this.$emit('success')
              })
              .finally(() => (this.loading = false))
          } catch (error) {
            console.log(error)
            $notification['error']({ message: '提示', description: '脚本错误' })
            this.loading = false
          }
        }
      })
    }
  }
}
</script>

<style lang="less" scoped></style>
