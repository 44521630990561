<template>
  <a-modal
    v-model="visible"
    title="选择技能"
    width="716px"
    :destroyOnClose="true"
    :bodyStyle="{ padding: 0 }"
    :ok-button-props="{ props: { shape: 'round', loading: buttonLoading } }"
    :cancel-button-props="{ props: { shape: 'round' } }"
    @ok="handleOk"
    @cancel="selected = null"
  >
    <a-row :gutter="24" style="padding:24px">
      <a-form-model class="ant-advanced-search-form">
        <a-col :lg="10" :md="10" :sm="24">
          <a-form-model-item label="搜索">
            <a-input-search
              v-model="query.goodsName"
              allowClear
              placeholder="请输入技能包名称"
              style="width: 100%"
              @search="initData"
            />
          </a-form-model-item>
        </a-col>
        <a-col :lg="14" :md="14" :sm="24">
          <a-form-model-item label="领域">
            <h-cascader
              v-model="field"
              :options="mainFieldsOptions"
              :fieldNames="{
                label: 'name',
                value: 'id',
                children: 'children'
              }"
              change-on-select
              allowClear
              placeholder="全部分类"
              style="width:100%"
              @change="initData"
            />
          </a-form-model-item>
        </a-col>
      </a-form-model>
    </a-row>

    <skill-table ref="table" @change="e => (selected = e)"></skill-table>
  </a-modal>
</template>

<script>
import * as commonApi from '@/api/common'
import SkillTable from './SkillTable'

export default {
  components: {
    SkillTable
  },
  data() {
    return {
      visible: false,
      buttonLoading: false,
      query: {
        goodsName: '',
        classification: undefined,
        parentClassification: undefined
      },
      field: [],
      mainFieldsOptions: [],
      selected: null
    }
  },
  mounted() {
    commonApi.fieldsOptions().then(res => {
      this.mainFieldsOptions = res.data.filter(v => v.level === 'MAIN')
    })
  },
  methods: {
    showModal() {
      this.visible = true
      this.query = {
        goodsName: '',
        classification: undefined,
        parentClassification: undefined
      }
      this.initData()
    },
    initData() {
      const { $nextTick, query, field, $refs } = this

      query.parentClassification = field[0]
      query.classification = field[1]

      $nextTick(() => {
        $refs.table.initData(query)
      })
    },
    handleOk() {
      const { selected } = this

      if (selected) {
        this.visible = false
        this.$emit('submit', selected)
      } else {
        this.$emit('submit', null)
        this.visible = false
      }
    }
  }
}
</script>

<style lang="less" scoped>
.ant-advanced-search-form /deep/ .ant-form-item {
  display: flex;
  margin-bottom: 0;
}
.ant-advanced-search-form /deep/ .ant-form-item-control-wrapper {
  flex: 1;
}
</style>
