<template>
  <div>
    <template v-if="current === 'LIST'">
      <a-card style="margin-bottom: 24px">
        <a-row type="flex" :gutter="[16, 16]">
          <a-col :span="8" class="option-wrapper">
            <span>机构名称：</span>
            <h-cascader
              :options="mechanismOptions"
              :value="defaultMechanism"
              change-on-select
              allowClear
              @change="onChange"
              :fieldNames="{ label: 'name', value: 'id', children: 'childrenList' }"
              placeholder="请选择"
              style="flex: 1"
            >
            </h-cascader>
          </a-col>
          <a-col :span="6" class="option-wrapper">
            <span>创建时间：</span>
            <a-date-picker
              v-model="query.createTimestamp"
              valueFormat="YYYY-MM-DD"
              style="flex: 1"
              @change="initData(query)"
            />
          </a-col>
          <a-col :span="8" class="option-wrapper">
            <span>商品名称：</span>
            <a-input-search
              v-model="query.goodsName"
              allowClear
              placeholder="请输入商品名称"
              style="flex: 1"
              @search="initData(query)"
            />
          </a-col>
        </a-row>
      </a-card>

      <a-card :bodyStyle="{ padding: 0 }">
        <main-table ref="table" v-if="$auth('redemptionCode_list')" @detail-check="handleDetail"></main-table>
      </a-card>
    </template>

    <detail-main v-else ref="table" @back="switchCurrent('LIST')"></detail-main>
  </div>
</template>

<script>
import * as commonApi from '@/api/common'
import MainTable from './components/Table'
import DetailMain from './detail/index'

export default {
  components: {
    MainTable,
    DetailMain,
  },
  data() {
    return {
      current: 'LIST',
      defaultMechanism: [],
      query: {
        mechanismId: undefined,
        createTimestamp: null,
        goodsName: undefined,
      },
      mechanismOptions: [],
    }
  },
  mounted() {
    commonApi.mechanismOptions().then((res) => {
      this.mechanismOptions = res.data
    })

    this.initData(this.query)
  },
  methods: {
    onChange(record) {
      this.defaultMechanism = record
      this.$set(this.query, 'mechanismId', record[record.length - 1])
      this.initData(this.query)
    },
    initData(query = {}) {
      const { $nextTick, $refs } = this
      $nextTick(() => {
        $refs.table.initData(query)
      })
    },
    switchCurrent(val, query = {}) {
      this.current = val

      this.initData(query)
    },
    handleDetail(record) {
      this.current = 'DETAIL'
      this.initData({ redemptionCodeId: record.id })
    },
  },
}
</script>

<style lang="less" scoped>
.option-wrapper {
  display: flex;
  align-items: center;
}
</style>
