<template>
  <div>
    <a-card style="margin-bottom: 24px">
      <a-row type="flex" :gutter="[16, 16]">
        <a-col :span="6" class="option-wrapper">
          <span>用户姓名：</span>
          <a-input-search
            v-model="query.username"
            allowClear
            placeholder="请输入"
            style="flex: 1"
            @search="initData()"
          />
        </a-col>
        <a-col :span="6" class="option-wrapper">
          <span>用户账号：</span>
          <a-input-search
            v-model="query.cellphone"
            allowClear
            placeholder="请输入"
            style="flex: 1"
            @search="initData()"
          />
        </a-col>
        <a-col :span="6" class="option-wrapper option-wrapper-date">
          <span>注册时间：</span>
          <a-range-picker
            style="flex: 1"
            v-model="query.date"
            valueFormat="YYYY-MM-DD"
            @change="onChangeDate"
          ></a-range-picker>
        </a-col>
        <a-col :span="6" class="option-wrapper option-wrapper-btn" v-if="$auth('redemptionCode_batch_use')">
          <a-button type="primary" shape="round" @click="handlebulkExchange()" :disabled="selectUserList.length <= 1"
            >批量兑换</a-button
          >
        </a-col>
      </a-row>
    </a-card>
    <a-card :bodyStyle="{ padding: 0 }">
      <main-table ref="table" @hanlderMulChoice="hanlderMulChoice"></main-table>
    </a-card>
    <!-- 批量兑换  -->
    <bulk-exchange ref="bulkExchange" @handlesecondconfirm="handlesecondconfirm($event)"></bulk-exchange>
    <!-- 批量兑换二次确认  -->
    <second-confirm ref="SecondConfirm"></second-confirm>
  </div>
</template>

<script>
import { REDEMTION_CODE_STATUS } from '@/store/dictionary'
import MainTable from './components/Table'
import BulkExchange from './components/BulkExchange'
import SecondConfirm from './components/SecondConfirm'
import { cloneDeep } from 'lodash'

export default {
  components: {
    MainTable,
    BulkExchange,
    SecondConfirm,
  },
  data() {
    return {
      query: {
        username: '',
        cellphone: '',
        startTimestamp: '', // 开始时间
        endTimestamp: '', // 结束时间
        date: [],
      },
      selectUserList: [],
      statusOptions: REDEMTION_CODE_STATUS,
      tempSelectData: {},
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    hanlderMulChoice(list) {
      this.selectUserList = list
    },
    // 日期选择打开、关闭
    onChangeDate() {
      this.$nextTick(() => {
        const { date } = this.query
        this.query.startTimestamp = date[0]
        this.query.endTimestamp = date[1]
        // eslint-disable-next-line no-unused-expressions
        this.initData()
      })
    },
    initData() {
      // 删掉冗余字段date
      const query = cloneDeep(this.query)
      delete query.date
      // 拉数据
      this.$refs.table.initData(query)
    },
    // 批量兑换选择兑换码
    handlebulkExchange() {
      this.$refs.bulkExchange.showModal({ list: this.selectUserList })
    },
    // 批量兑换二次确认
    handlesecondconfirm(data) {
      data.selectUserList = this.selectUserList
      this.$refs.SecondConfirm.showModal(data)
    },
  },
}
</script>

<style lang="less" scoped>
.option-wrapper {
  display: flex;
  align-items: center;
}
.option-wrapper-btn {
  justify-content: flex-end;
}
</style>
