import request from '@/utils/request'

const redemptionCodeApi = {
  list: '/redemptionCode',
  detail: '/redemptionCode/info',
  studentHistory: '/redemptionCode/studentInfo',
  useCode: '/redemptionCode/user',
  create: '/redemptionCode',
  batchUser: '/redemptionCode/batchUser',
  exportExcel: id => `/redemptionCode/export/${id}`,
  codeDetail: redemptionCode => `/redemptionCode/${redemptionCode}`
}

// 批量兑换
export function batchUser(parameter) {
  return request({
    url: redemptionCodeApi.batchUser,
    method: 'patch',
    params: parameter
  })
}

export function list(parameter) {
  return request({
    url: redemptionCodeApi.list,
    method: 'get',
    params: parameter
  })
}

export function detail(parameter) {
  return request({
    url: redemptionCodeApi.detail,
    method: 'get',
    params: parameter
  })
}

export function studentHistory(parameter) {
  return request({
    url: redemptionCodeApi.studentHistory,
    method: 'get',
    params: parameter
  })
}

export function useCode(parameter) {
  return request({
    url: redemptionCodeApi.useCode,
    method: 'patch',
    params: parameter
  })
}

export function create(parameter) {
  return request({
    url: redemptionCodeApi.create,
    method: 'post',
    data: parameter
  })
}

export function exportExcel(parameter) {
  return request({
    url: redemptionCodeApi.exportExcel(parameter),
    method: 'get'
  })
}
// 查看兑换码内容
export function codedetail(parameter) {
  const { redemptionCode } = parameter
  return request({
    url: redemptionCodeApi.codeDetail(redemptionCode),
    method: 'get'
  })
}
